import Container from '@/components/Container';
import PageComponent from '@/components/PageComponent';
import Section from '@/components/Section';
import SectionHeader from '@/components/SectionHeader';
import { Tit } from '@/components/Titles';
import { HomepageAgreeContent } from '@/data/agreements';
import LayoutWithTitle from '@/layouts/LayoutWithTitle';
import { PageProps } from 'gatsby';
import React, { FC } from 'react';

const Service: FC<PageProps> = ({ location }) => (
  <LayoutWithTitle location={location} title="이용약관">
    <Section className="by-sub-main-layout">
      <Container>
        <SectionHeader className="with-desc">
          <h3>
            <Tit size="s1-5" color="sky" weight="normal">
              <PageComponent id="title1">
                유니세프한국위원회 사이트의 서비스 이용과 관련하여
                <br />
                필요한 사항을 규정합니다
              </PageComponent>
            </Tit>
          </h3>
        </SectionHeader>
        {HomepageAgreeContent}
      </Container>
    </Section>
  </LayoutWithTitle>
);

export default Service;
